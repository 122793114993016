import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Line } from 'app/components/Common/Line'
import React, { memo } from 'react'

import { Item, Props as ItemProps } from './Item'

export interface Props {
  items: ItemProps[]
  title?: string
}

export const ZigZag = memo(function ZigZag({ items, title }: Props) {
  if (items.length < 1) {
    return null
  }

  return (
    <Container>
      {title ? (
        <FadeInUp>
          <Title dangerouslySetInnerHTML={{ __html: title }} />
          <Line className="line" variant="compact" />
        </FadeInUp>
      ) : null}

      <Items>
        {items.map((item, index) => (
          <Item key={index} {...item} />
        ))}
      </Items>
    </Container>
  )
})

const Container = styled.section`
  margin: 12.5rem 0;
  text-align: center;

  .line {
    margin-right: auto;
    margin-left: auto;
  }

  @media (max-width: 1199px) {
    margin: 7.5rem 0;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3.125rem;
  font-weight: 500;
  line-height: 3.875rem;

  b {
    color: ${({ theme }) => theme.colors.variants.primaryLight};
    font-style: italic;
    font-weight: 500;
  }

  @media (max-width: 1199px) {
    font-size: 2.25rem;
    line-height: 2.75rem;
  }
`

const Items = styled.div``
