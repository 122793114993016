import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  description?: string
  images?: ImageProps[]
  title?: string
}

export const Item = memo(function Item({ description, images, title }: Props) {
  return (
    <Container row stretch wrap>
      <LeftSide dial={4}>
        {title ? (
          <Title>
            <Label>{title}</Label>
          </Title>
        ) : null}

        {description ? (
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        ) : null}
      </LeftSide>

      <RightSide row>
        {images
          ? images.slice(0, 2).map((item, index) => (
              <LazyLoadComponent key={index}>
                <Image {...item} />
              </LazyLoadComponent>
            ))
          : null}
      </RightSide>
    </Container>
  )
})

const Container = styled(FlexBox)`
  margin-top: 1.875rem;
  text-align: left;
  &:first-of-type {
    margin-top: 5rem;
  }
  &:nth-of-type(even) {
    > div {
      &:first-of-type {
        order: 2;
        padding-right: 10.278vw;
        padding-left: 9.236vw;
      }
      &:last-of-type {
        picture {
          margin-right: 1.875rem;
          margin-left: auto;
        }
      }
    }
  }

  @media (max-width: 1199px) {
    &:nth-of-type(even) {
      > div {
        &:first-of-type {
          padding-right: 1.875rem;
          padding-left: 1.875rem;
        }
      }
    }
  }

  @media (max-width: 1023px) {
    &:nth-of-type(even) {
      > div {
        &:last-of-type {
          picture {
            margin-right: 0;
          }
        }
      }
    }
  }
`

const LeftSide = styled(FlexBox)`
  width: calc(50% - 0.9375rem);
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  padding: 7.5rem 9.236vw 7.5rem 10.278vw;

  @media (max-width: 1199px) {
    padding: 3.75rem 1.875rem;
  }

  @media (max-width: 1023px) {
    width: 100%;
    order: 2;
  }
`

const Title = styled.div``

const Label = styled.span`
  display: inline-flex;
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.875rem;
  line-height: 2.8125rem;
  &:after {
    content: '';
    width: 3rem;
    height: 0.125rem;
    background: ${({ theme }) => theme.colors.variants.primaryLight};
    margin-left: 1.5rem;
    transform: translateY(1.375rem);
  }

  @media (max-width: 1199px) {
    font-size: 1.75rem;
    line-height: 2.25rem;
    &:after {
      transform: translateY(1.125rem);
    }
  }

  @media (max-width: 1023px) {
    &:after {
      display: none;
    }
  }
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.125rem;
  font-weight: 300;
  line-height: 2rem;
  margin-top: 2.5rem;

  @media (max-width: 1199px) {
    font-size: 1rem;
  }

  @media (max-width: 1023px) {
    margin-top: 1.875rem;
  }
`

const RightSide = styled(FlexBox)`
  width: calc(50% + 0.9375rem);

  picture {
    width: calc(50% - 1.875rem);
    height: 100%;
    min-height: 74vh;
    margin-left: 1.875rem;
    position: relative;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1023px) {
    width: 100%;
    justify-content: space-between;

    picture {
      width: calc(50% - 0.375rem);
      min-height: 28vh;
      margin-left: 0;
    }
  }
`
